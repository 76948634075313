import styled from "styled-components";
import {
  fontH2,
  fontH4,
  fontBodyRegular,
  fontBodyStrong,
} from "../../styles/fonts";
import colors from "../../styles/colors";

const RichText = styled.div`
  ${fontBodyRegular};
  font-size: 18px;
  line-height: 26px;

  h2 {
    ${fontH2};
    margin: 68px 0 32px;
  }

  h3 {
    ${fontH4};
    margin: 32px 0 16px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    ${fontBodyStrong};
    font-size: 18px;
    line-height: 26px;
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    list-style-position: outside;
    margin-left: 48px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: lower-roman;
    counter-reset: list;
    & > li {
      list-style: none;
      counter-increment: list;
      padding-left: 20px;
      &::marker {
        content: "(" counter(list, lower-roman) ")";
      }
    }
  }

  li {
    margin-bottom: 1.5em;
  }

  a,
  a:visited {
    color: ${colors.blue};
  }

  p.block-img {
    margin: 60px 0;
  }

  img {
    width: 100%;
    display: block;
  }
`;

export default RichText;
