import React from "react";
import styled from "styled-components";
import Grid from "../../styles/Grid";
import { fontFG52m } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";

const Title = styled.h1`
  ${fontFG52m};
`;

const Container = styled(Grid)`
  text-align: center;
  padding: 90px 0 60px;
  ${Title} {
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 120px 0 90px;
    ${Title} {
      grid-column: 5 / span 6;
    }
  }
`;

const TitleSection = ({ title, ...props }) => (
  <Container>
    <Title>{title}</Title>
  </Container>
);

export default TitleSection;
