import React, { Component } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import TitleSection from "../components/standard/TitleSection";
import BodySection from "../components/standard/BodySection";

class StandardPage extends Component {
  // componentDidMount() {
  //   const pageTitle = this.props.data.prismicStandardPage.data.title;
  //   window.analytics.page(pageTitle);
  // }

  render() {
    const {
      location,
      pageContext: { locale },
      data,
    } = this.props;
    const pageData = data.prismicStandardPage.data;

    return (
      <Layout location={location} locale={locale}>
        <Seo
          title={`${pageData.title} | Workmate`}
          description={pageData.meta_description}
        />
        <TitleSection title={pageData.title} />
        <BodySection body={pageData.body} />
      </Layout>
    );
  }
}

export default StandardPage;

export const query = graphql`
  query StandardPageQuery($localeKey: String, $slug: String) {
    prismicStandardPage(lang: { eq: $localeKey }, uid: { eq: $slug }) {
      data {
        title
        meta_description
        body {
          html
        }
      }
    }
  }
`;
