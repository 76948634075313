import React from "react";
import Grid from "../../styles/Grid";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import RichText from "../shared/RichText";

const Container = styled(Grid)`
  padding: 0 0 60px;

  ${RichText} {
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 0 0 90px;
    ${RichText} {
      grid-column: 5 / span 6;
    }
  }
`;

const BodySection = ({ body, ...props }) => (
  <Container {...props}>
    <RichText dangerouslySetInnerHTML={{ __html: body.html }} />
  </Container>
);

export default BodySection;
